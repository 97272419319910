/**
 * @generated SignedSource<<5d2b3fcda62bece726437844f275eec1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductStatus = "draft" | "published" | "archived" | "unlisted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductCardDetailsFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly status: ProductStatus;
  readonly startsAt: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProductTypeTagFragment" | "productUtils_useIsProductLiveFragment">;
  readonly " $fragmentType": "ProductCardDetailsFragment";
};
export type ProductCardDetailsFragment$key = {
  readonly " $data"?: ProductCardDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductCardDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductCardDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startsAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductTypeTagFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "productUtils_useIsProductLiveFragment"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "48443d5683b1d90db72b8491543aed44";

export default node;
