/**
 * @generated SignedSource<<ef92bcc4a0a5cb2a809bc3bd7a99354b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductCardCallToActionButtonFragment$data = {
  readonly slug: string;
  readonly hasEnded: boolean;
  readonly viewerMembership: {
    readonly id: string;
  } | null;
  readonly viewerApplication: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "ProductCardCallToActionButtonFragment";
};
export type ProductCardCallToActionButtonFragment$key = {
  readonly " $data"?: ProductCardCallToActionButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductCardCallToActionButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductCardCallToActionButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasEnded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductMembership",
      "kind": "LinkedField",
      "name": "viewerMembership",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApplication",
      "kind": "LinkedField",
      "name": "viewerApplication",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "33d2961c6030465cb4b41994cd446846";

export default node;
