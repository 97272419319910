import Badge from "@/admin/experiences/badges/Badge"
import { ProductDropdownItemFragment$key } from "@/product/common/__generated__/ProductDropdownItemFragment.graphql"
import ProductUtils from "@/product/util/productUtils"
import { DiscoChip } from "@disco-ui"
import DiscoDropdownItem, {
  DiscoDropdownItemProps,
} from "@disco-ui/dropdown/DiscoDropdownItem"
import { TestIDProps } from "@utils/typeUtils"
import { graphql, useFragment } from "react-relay"

interface Props
  extends TestIDProps,
    Pick<DiscoDropdownItemProps, "hoverBackgroundColor"> {
  productKey: ProductDropdownItemFragment$key
  className?: string
  showDraftStatus?: boolean
}

function ProductDropdownItem(props: Props) {
  const {
    testid,
    productKey,
    className,
    showDraftStatus = false,
    hoverBackgroundColor,
  } = props

  const product = useFragment<ProductDropdownItemFragment$key>(
    graphql`
      fragment ProductDropdownItemFragment on Product {
        name
        type
        startsAt
        endsAt
        status
        badge {
          ...BadgeFragment
        }
      }
    `,
    productKey
  )

  const startDateText = ProductUtils.getStartDateText(product.type, product.startsAt)

  return (
    <DiscoDropdownItem
      backgroundColor={"transparent"}
      hoverBackgroundColor={hoverBackgroundColor}
      className={className}
      testid={testid}
      icon={<Badge badgeKey={product.badge!} size={24} />}
      title={product.name}
      rightButton={
        showDraftStatus &&
        product.status === "draft" && <DiscoChip color={"yellow"} label={"Draft"} />
      }
      subtitle={renderSubtitle()}
    />
  )

  function renderSubtitle() {
    return product.startsAt || product.endsAt
      ? `${startDateText} ·
  ${ProductUtils.displayDuration(product)}`
      : startDateText
  }
}

export default ProductDropdownItem
