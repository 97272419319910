import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useFormStore } from "@/core/form/store/FormStore"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import { generateReadablePricing } from "@/pricing/pricingUtils"
import { CheckoutPlaygroundMutation } from "@/product/checkout/playground/__generated__/CheckoutPlaygroundMutation.graphql"
import { CheckoutPlaygroundQuery } from "@/product/checkout/playground/__generated__/CheckoutPlaygroundQuery.graphql"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import {
  DiscoButton,
  DiscoChip,
  DiscoIcon,
  DiscoSection,
  DiscoSpinner,
  DiscoText,
} from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import DiscoFloatingBar from "@disco-ui/floating-bar/DiscoFloatingBar"
import classNames from "classnames"
import { observer } from "mobx-react-lite"
import pluralize from "pluralize"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"

function CheckoutPlayground() {
  const { authUser } = useAuthUser()
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()

  const { organization } = useLazyLoadQuery<CheckoutPlaygroundQuery>(
    graphql`
      query CheckoutPlaygroundQuery($id: ID!, $purchasableEntityIds: [ID!]!) {
        organization: node(id: $id) {
          ... on Organization {
            purchasableEntities(purchasableEntityIds: $purchasableEntityIds) {
              edges {
                node {
                  id
                  membershipPlan {
                    name
                    cover
                  }
                  membershipBenefit {
                    membershipPlan {
                      name
                    }
                    product {
                      name
                      cover
                    }
                  }
                  pricings {
                    edges {
                      node {
                        id
                        amountCents
                        frequency
                        kind
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization?.id || "",
      purchasableEntityIds: [],
    }
  )

  const purchasableEntities = Relay.connectionToArray(organization?.purchasableEntities)

  const form = useFormStore<CheckoutPlaygroundMutation>(
    graphql`
      mutation CheckoutPlaygroundMutation($input: CreateStripeCheckoutInput!) {
        response: createStripeCheckout(input: $input) {
          url
          errors {
            field
            message
          }
        }
      }
    `,
    {
      pricingIds: [],
      successUrl: window.location.href,
      cancelUrl: window.location.href,
    }
  )

  // TODO: Remove the `isStaff` field from the `AuthUserContext` when this component is removed
  // Only show for staff
  if (!authUser?.isStaff) return null

  // Only show for communities that are prepared for Stripe acacia
  if (activeOrganization?.checkoutVersion !== "stripe_acacia") return null

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <DiscoText variant={"heading-lg-700"} marginBottom={4}>
          {"Checkout Playground"}
        </DiscoText>

        <div className={classes.list}>
          {purchasableEntities.map((entity) => {
            const { membershipPlan, membershipBenefit } = entity
            // TODO: There is only 1 for now
            const pricing = Relay.connectionToArray(entity.pricings)[0]
            if (!pricing) return null

            const name = membershipPlan?.name || membershipBenefit?.product?.name || ""
            const location = membershipBenefit?.membershipPlan?.name || ""
            const type = membershipPlan ? "Membership Plan" : "Membership Benefit"
            const cover = membershipPlan?.cover || membershipBenefit?.product?.cover
            const isSelected = form.state.pricingIds.includes(pricing.id)

            return (
              <DiscoContainerButton
                key={entity.id}
                onClick={() => handleSelectEntity(pricing.id)}
              >
                <DiscoSection
                  className={classNames(classes.purchasableEntity, {
                    [classes.selected]: isSelected,
                  })}
                  groovyDepths={"insideCard"}
                  marginTop={1.5}
                  padding={2}
                >
                  <div className={classes.lhs}>
                    <div className={classes.coverWrapper}>
                      <CoverPhoto
                        coverPhoto={cover || defaultThumbnail}
                        customClassName={classes.cover}
                      />
                    </div>

                    <div className={classes.details}>
                      <div className={classes.title}>
                        <DiscoText variant={"body-lg-500"}>{name}</DiscoText>
                        <DiscoChip
                          label={type}
                          color={membershipPlan ? "blue" : "green"}
                        />
                      </div>

                      {membershipBenefit && (
                        <DiscoText
                          variant={"body-md"}
                          color={"text.secondary"}
                        >{`on ${location}`}</DiscoText>
                      )}
                    </div>
                  </div>

                  <div className={classes.rhs}>
                    <div className={classes.prices}>
                      <DiscoText variant={"body-md-500"}>
                        {generateReadablePricing(pricing, activeOrganization.currency)}
                      </DiscoText>
                    </div>
                  </div>
                </DiscoSection>
              </DiscoContainerButton>
            )
          })}
        </div>

        <DiscoFloatingBar
          open={Boolean(form.state.pricingIds.length)}
          content={pluralize("Entity", form.state.pricingIds.length, true)}
          icon={<DiscoIcon icon={"credit-card"} />}
          buttons={
            <DiscoButton
              onClick={handleSubmit}
              shouldDisplaySpinner={form.isSubmitting}
              disabled={form.isSubmitting}
            >
              {"Checkout"}
            </DiscoButton>
          }
        />
      </div>
    </div>
  )

  function handleSelectEntity(pricingId: GlobalID) {
    const hasEntity = form.state.pricingIds.includes(pricingId)
    if (hasEntity) {
      form.state.pricingIds.replace(
        form.state.pricingIds.filter((id) => id !== pricingId)
      )
    } else {
      form.state.pricingIds.push(pricingId)
    }
  }

  async function handleSubmit() {
    const { didSave, response } = await form.submit(form.state)
    if (!didSave || !response) return

    // Extract the URL from the response and redirect
    if ("url" in response && response.url) {
      window.open(response.url, "_blank")
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: "100vh",
    maxWidth: theme.measure.page.contentMaxWidth,
    padding: theme.spacing(5),
  },
  list: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    width: "100%",
  },
  purchasableEntity: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: theme.spacing(1),
    width: "100%",

    "&:hover": {
      cursor: "pointer",
      boxShadow: theme.palette.groovyDepths.boxShadow,
    },
  },
  selected: {
    border: `2px solid ${theme.palette.success.main}`,
  },
  coverWrapper: {
    width: 150,
    marginRight: theme.spacing(1.5),
  },
  cover: {
    borderRadius: theme.measure.borderRadius.default,
  },
  details: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    justifyContent: "space-between",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
    alignItems: "center",
  },
  lhs: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
  },
  rhs: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    alignItems: "flex-end",
  },
  prices: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
}))

export function CheckoutPlaygroundSkeleton() {
  return <DiscoSpinner size={"lg"} absoluteCenter />
}

export default Relay.withSkeleton({
  component: observer(CheckoutPlayground),
  skeleton: CheckoutPlaygroundSkeleton,
})
