import Badge from "@/admin/experiences/badges/Badge"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import defaultThumbnail from "@/core/ui/images/public-pages/default-thumbnail.png"
import { usePricingDisplayValue } from "@/pricing/pricingUtils"
import { ProductWithDetailsFragment$key } from "@/product/common/__generated__/ProductWithDetailsFragment.graphql"
import ProductUtils from "@/product/util/productUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import CoverPhoto from "@components/cover-photo/CoverPhoto"
import { DiscoLink, DiscoText } from "@disco-ui"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useFragment } from "react-relay"
import { generatePath, useHistory } from "react-router-dom"
import { graphql } from "relay-runtime"

interface Props extends TestIDProps {
  productKey: ProductWithDetailsFragment$key
  linkToProduct?: boolean
  hideDetails?: boolean
  hideCover?: boolean
  size?: "default" | "small"
}

function ProductWithDetails(props: Props) {
  const {
    productKey,
    hideDetails,
    hideCover,
    linkToProduct,
    size = "default",
    testid = "ProductWithDetails",
  } = props

  const classes = useStyles({
    size: size === "default" ? 48 : 40,
  })
  const isMobile = useIsMobile()
  const history = useHistory()
  const drawer = useGlobalDrawer("membershipPlanSettings")
  const activeOrganization = useActiveOrganization()!

  const product = useFragment<ProductWithDetailsFragment$key>(
    graphql`
      fragment ProductWithDetailsFragment on Product {
        id
        name
        startsAt
        endsAt
        type
        slug
        pricing {
          ...pricingUtils_usePricingDisplayValue
        }
        ...ProductTypeTagFragment
        badge {
          ...BadgeFragment
        }
        cover
      }
    `,
    productKey
  )

  const { startsAt, name, cover, type } = product
  const startDateText = ProductUtils.getStartDateText(type, startsAt)
  const text = (
    <DiscoText variant={"body-sm-700"} testid={`${testid}.name`}>
      {name}
    </DiscoText>
  )
  const pricingValue = usePricingDisplayValue({
    pricingKey: product.pricing,
    currency: activeOrganization.currency,
  })

  return (
    <div className={classes.root}>
      {!hideCover && (
        <div
          className={classNames(classes.iconWrapper, {
            [classes.coverImageWrapper]: type !== "course" && type !== "pathway",
          })}
        >
          {getCover()}
        </div>
      )}

      <div>
        {linkToProduct ? (
          <DiscoLink onClick={handleLinkToProduct}>{text}</DiscoLink>
        ) : (
          text
        )}
        {!hideDetails && (
          <DiscoText
            variant={"body-xs-500"}
            color={"text.secondary"}
            testid={`${testid}.details`}
          >
            {getDetails()}
          </DiscoText>
        )}
      </div>
    </div>
  )

  function handleLinkToProduct() {
    switch (type) {
      case "course":
      case "pathway":
        history.push(
          generatePath(ROUTE_NAMES.PRODUCT.DASHBOARD, {
            productSlug: product.slug,
          })
        )
        break
      case "membership_plan":
        drawer.open({
          drawerMembershipPlanId: product.id,
          membershipPlanSettingsTab: "details",
        })
        break
    }
  }

  function getCover() {
    switch (type) {
      case "course":
      case "pathway":
        return (
          <Badge
            badgeKey={product.badge!}
            size={isMobile || size === "small" ? 40 : 48}
          />
        )
      default:
        return (
          <CoverPhoto
            coverPhoto={cover || defaultThumbnail}
            customClassName={classes.cover}
          />
        )
    }
  }

  function getDetails() {
    switch (type) {
      case "course":
        return product.startsAt || product.endsAt
          ? `${startDateText} · 
    ${ProductUtils.displayDuration(product)}`
          : startDateText
      case "membership_plan":
        return pricingValue
      default:
        return null
    }
  }
}

type StyleProps = {
  size: number
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  iconWrapper: ({ size }: StyleProps) => ({
    width: size,
    height: size,
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
  }),
  coverImageWrapper: {
    width: 100,
  },
  cover: {
    borderRadius: theme.measure.borderRadius.default,
  },
}))

export default ProductWithDetails
