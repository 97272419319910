/**
 * @generated SignedSource<<94b45e43096fd42b2b9a1b099445ff7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PricingFrequency = "weekly" | "monthly" | "annually" | "%future added value";
export type PricingKind = "one_time" | "recurring" | "free" | "%future added value";
export type CheckoutPlaygroundQuery$variables = {
  id: string;
  purchasableEntityIds: ReadonlyArray<string>;
};
export type CheckoutPlaygroundQuery$data = {
  readonly organization: {
    readonly purchasableEntities?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly membershipPlan: {
            readonly name: string;
            readonly cover: string;
          } | null;
          readonly membershipBenefit: {
            readonly membershipPlan: {
              readonly name: string;
            };
            readonly product: {
              readonly name: string;
              readonly cover: string;
            } | null;
          } | null;
          readonly pricings: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly amountCents: number;
                readonly frequency: PricingFrequency | null;
                readonly kind: PricingKind;
              };
            }>;
          };
        };
      }>;
    };
  } | null;
};
export type CheckoutPlaygroundQuery = {
  variables: CheckoutPlaygroundQuery$variables;
  response: CheckoutPlaygroundQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "purchasableEntityIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "purchasableEntityIds",
    "variableName": "purchasableEntityIds"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cover",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PricingNodeConnection",
  "kind": "LinkedField",
  "name": "pricings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PricingNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Pricing",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "amountCents",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "frequency",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CheckoutPlaygroundQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "PurchasableEntityNodeConnection",
                "kind": "LinkedField",
                "name": "purchasableEntities",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PurchasableEntityNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PurchasableEntity",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "membershipPlan",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MembershipBenefit",
                            "kind": "LinkedField",
                            "name": "membershipBenefit",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "membershipPlan",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": (v6/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CheckoutPlaygroundQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "PurchasableEntityNodeConnection",
                "kind": "LinkedField",
                "name": "purchasableEntities",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PurchasableEntityNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PurchasableEntity",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "membershipPlan",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MembershipBenefit",
                            "kind": "LinkedField",
                            "name": "membershipBenefit",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "membershipPlan",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": (v8/*: any*/),
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1effa85efe26e50d18bb65692368ef68",
    "id": null,
    "metadata": {},
    "name": "CheckoutPlaygroundQuery",
    "operationKind": "query",
    "text": "query CheckoutPlaygroundQuery(\n  $id: ID!\n  $purchasableEntityIds: [ID!]!\n) {\n  organization: node(id: $id) {\n    __typename\n    ... on Organization {\n      purchasableEntities(purchasableEntityIds: $purchasableEntityIds) {\n        edges {\n          node {\n            id\n            membershipPlan {\n              name\n              cover\n              id\n            }\n            membershipBenefit {\n              membershipPlan {\n                name\n                id\n              }\n              product {\n                name\n                cover\n                id\n              }\n              id\n            }\n            pricings {\n              edges {\n                node {\n                  id\n                  amountCents\n                  frequency\n                  kind\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "febd28bb48218e2bc69420a977002d39";

export default node;
